import App from './App.svelte'
import '@common/dayjs';
import './index.css';
import "./commonStyles/utilities.css"
import 'virtual:windi.css'


//@ts-ignore
const app = new App({
	target: document.body,
})

export default app
