import { ReactiveObject } from "./ReactiveObject";
import type { Dayjs, ManipulateType } from "dayjs";
import { currentUser } from "./basicStores";
import { createInstance } from 'localforage'
import dayjs from "dayjs";
import type { ApiClient } from "./trpc";
import { calendarEventsStorageKey } from "./constants";
import type { IExtendedWritableEvents } from "./ExtendedWritable";
import { IFirebaseMessagingEventsTypes, sendCloudEventMessage } from "./firebaseMessagingEvents";
import type { EventApi } from "svelte-fullcalendar";
import { getWhatsappShareUrl } from "./whatsapp";
import { BookEventViews } from "./calendarUtils";


export interface ITimeInterval {
	value: number;
	unit: ManipulateType;
}

export interface ITimeRange {
	start: Dayjs;
	end: Dayjs;
	recurring?: boolean;
}

export interface ICalendarStoreOptions {
	events: any
}

export interface IEvent {
	id: string;
	title?: string;
	description?: string;
	start: number;
	end: number;
	isBooked?: boolean;
	acceptedBy?: ({
		id: string;
		acceptedTime: number;
	})[];
	date: number;
	recurring?: boolean;
	updated?: number;
	created?: number;	
	extendedProps?: Record<string, any>;
}

export interface IEventChange {
	start: number;
	end: number;

	startDate: number;
	endDate: number;

	userId: string;
	subEventId: string;
	eventId: string;
	id: string;
	message?: string;
}

export interface ICalendarEvent {
	id: string;
	title: string;
	description: string;
	events: IEvent[];
	userId: string;
	updated?: number;
	created?: number;
}



export const createEvent = async (client: ApiClient, event: ICalendarEvent) => {
	const data = await client.mutation("createInvite", event)
	return data
}


export const getEvent = async (client: ApiClient, eventId: string): Promise<ICalendarEvent | undefined> => {
	const data = await client.query("getInvite", { inviteId: eventId })
	return data
}


export const updateEvent = async (client: ApiClient, eventId: string, callback: (event: ICalendarEvent) => ICalendarEvent) => {
	const event: ICalendarEvent | undefined = await getEvent(client, eventId)
	// debugger

	if (event) {
		const newEvent = callback(event)
		await client.mutation("updateInvite", {
			inviteId: eventId,
			updatedData: newEvent
		})
	} else {
		throw new Error("Event not found")
	}
}


export const eventRecurrancePossible = (date1: Dayjs, date2: Dayjs) => {
	const week1 = date1.isoWeek()
	const week2 = date2.isoWeek()

	return week1 === week2
}


export interface ICalendarCallbackEvents extends IExtendedWritableEvents{
	eventCreate: {
		event: ICalendarEvent
	},
	eventUpdate: {
		event: ICalendarEvent
	},
	eventDelete: {
		eventId: string
	},

	eventChangeCreate: {
		eventChange: IEventChange
	},
	eventChangeUpdate: {
		eventChange: IEventChange
	},
	eventChangeDelete: {
		eventChangeId: string
	},
	changesUpdated: {
		changes: IEventChange[]
	}
}


export class CalendarEventsStore extends ReactiveObject<{
	events: ICalendarEvent[];
	changes: IEventChange[];
}, ICalendarCallbackEvents>{

	public storage: LocalForage = createInstance({
		name: calendarEventsStorageKey
	});
	public client: ApiClient
	public disableRealtimeSyncForEvent: { [key: string]: () => void } = {}
	public disableRealtimeSync: () => void = () => { }
	public listeningRealtimeChangesForEvents: string[] = []
	public isInitializing: boolean | Promise<boolean> = false
	constructor(options: {
		events: ICalendarEvent[],
		changes: IEventChange[]
	},
		client: ApiClient
	) {
		super(options);
		this.client = client
	}

	async createEvent(event: ICalendarEvent) {
		const id = await createEvent(this.client, event)
		event.id = id
		event.updated = Date.now()
		event.created = Date.now()
		this.update(state => {
			state.events.push(event)
			return state
		})
		await this.save();
		this.dispatch("eventCreate", { event })
		return id
	}


	async addEventChange(
		change: IEventChange
	) {
		this.update(state => {
			state.changes.push(change)
			return state
		})
		await this.save()
		
		this.dispatch("eventChangeCreate", { eventChange: change })
	}

	async removeEventChange(
		changeId: string
	) {
		this.update(state => {
			state.changes = state.changes.filter(c => c.id !== changeId)
			return state
		})
		await this.save()
		this.dispatch("eventChangeRemove", { changeId: changeId })
	}

	getEventChangeFromSubEventId(
		subEventId: string
	) {
		return this.get().changes.find(c => c.subEventId === subEventId)
	}
	getEventChangeFromEventId(
		subEventId: string
	) {
		return this.get().changes.find(c => c.eventId === subEventId)
	}

	async updateEvent(eventId: string, callback: (event: ICalendarEvent) => ICalendarEvent): Promise<ICalendarEvent> {
		let newEvent: ICalendarEvent
		await updateEvent(this.client, eventId, oldEvent => {
			newEvent = callback(oldEvent)
			newEvent.id = eventId
			newEvent.userId = oldEvent.userId
			newEvent.updated = Date.now()
			this.update(state => {
				const index = state.events.findIndex(e => e.id === eventId)
				if (index > -1) {
					if (newEvent) {
						state.events[index] = newEvent
					}
				}else{
					state.events.push(newEvent)
				}
				return state
			})
			return newEvent
		})
		await this.save();
		this.dispatch("eventUpdate", { event: newEvent! })
		return newEvent!
	}

	async updateSubEvent(
		opts: {
			eventId: string;
			subEventId: string;
		},
		callback: (event: IEvent) => IEvent
	) {
		const { eventId, subEventId } = opts
		await this.updateEvent(eventId, (event) => {
			const subEvent = event.events.find(e => e.id === subEventId)
			if(subEvent){
				const newSubEvent = callback(subEvent)
				const newEvent = {
					...event,
					events: event.events.map(e => e.id === subEventId ? newSubEvent : e)
				}
				return newEvent
			}else{
				return event
			}
		})

		this.dispatch("eventUpdate", { 
			event: this.get().events.find(e => e.id === eventId)!
		 })
	}

	async save() {
		await this.storage.setItem("events", this.get().events)
		await this.storage.setItem("changes", this.get().changes)
	}

	async load() {
		const events: ICalendarEvent[] = await this.storage.getItem("events") || []
		const changes: IEventChange[] = await this.storage.getItem("changes") || []
		this.set({
			events,
			changes
		})
	}

	async init() {

		if(this.isInitializing === true){
			return;
		}else if(this.isInitializing instanceof Promise){
			return await this.isInitializing
		}

		let resolve: (val: boolean) => void
		let reject: (err: any) => void
		this.isInitializing = new Promise<boolean>((res, rej) => {
			resolve = res
			reject = rej
		})
		try{
			await this.load()
			await this.syncFromServer()
			await this.handleRealtimeSync()

			resolve!(true)
		}catch(err){
			reject!(err)
		}
	}


	async updateChanges(
		cb: (changes: IEventChange[]) => IEventChange[]
	) {
		this.update(state => {
			state.changes = cb(state.changes)
			return state
		})
		await this.save()
		this.dispatch("changesUpdated", { changes: this.get().changes })
	}

	getSubEvent(opts: { eventId: string, subEventId: string }) {
		return this.getSubEventWithChanges(opts)
	}

	getEvent(eventId: string) {
		return this.get().events.find(e => e.id === eventId)
	}


	getReceivingUserId(opts: {
		eventId: string;
		subEventId: string;
		currentUserId: string
	}): string[] | undefined {
		const event = this.getEvent(opts.eventId);
		if (event) {
			const subEvent = this.getSubEvent(opts);
			if (subEvent) {
				return subEvent.acceptedBy?.map(a => a.id)
			}
		}

		return undefined
	}

	async mergeEvents(events: ICalendarEvent[]){
		const currentEvents = this.get().events
		const newEvents = events.filter(e => !currentEvents.find(c => c.id === e.id))
		this.update(state => {
			state.events = [...state.events, ...newEvents]
			
			for(let i = 0; i < state.events.length; i++){
				const event = state.events[i]
				const newEvent = newEvents.find(e => e.id === event.id)
				if(event?.updated && newEvent?.updated && event.updated < newEvent.updated){
					state.events[i] = newEvent
				}
			}
			return state
		})
		await this.save()
	}

	async mergeChanges(changes: IEventChange[]){
		const currentChanges = this.get().changes
		const newChanges = changes.filter(e => !currentChanges.find(c => c.id === e.id))
		this.update(state => {
			state.changes = [...state.changes, ...newChanges]
			return state
		})
		await this.save()
	}

	async syncFromServer() {
		const events = this.get().events;

		const today = dayjs().startOf("day")

		const promises = events.map(async (event) => {

			try {

				if (event.events.length === 0) {
					throw new Error("No events")
				}

				if (!event.id) {
					throw new Error("No id")
				}

				const shouldFetch = event.events.some(e => {
					return dayjs(e.end).isAfter(today)
				})

				if (shouldFetch) {
					const fetchedEvent = await getEvent(this.client, event.id)

					if (fetchedEvent) {

						event.events = event.events.map(e => {
							const fetchedEventIndex = fetchedEvent.events.findIndex(f => {
								return f.id === e.id
							})

							if (fetchedEventIndex > -1) {
								return fetchedEvent.events[fetchedEventIndex]
							} else {
								return e
							}
						})

						return event
					} else {
						return event
					}
				}

				return event
			} catch (err: any) {
				if (
					err.message === "Invite not found"
					|| err.message === "No id"
				) {
					return undefined
				}

				console.error(err);
				return event
			}
		})

		const newEvents = await Promise.all(promises)

		this.set({
			events: newEvents.filter(e => e !== undefined) as ICalendarEvent[],
			changes: this.get().changes
		})
		await this.save()
	}


	getSubEventWithChanges(opts: {
		subEventId: string;
		eventId: string;
	}): IEvent | undefined {
		const { subEventId, eventId } = opts
		const event = this.get().events.find(e => e.id === eventId)
		if (event) {
			const subEvent = event.events.find(e => e.id === subEventId)
			if (subEvent) {
				const changes = this.get().changes.filter(c => c.subEventId === subEventId)
				if (changes && changes.length) {

					// find latest change
					const change = changes.reduce((prev, current) => {
						return (prev.start > current.start) ? prev : current
					})


					const newSubEvent: IEvent = {
						...subEvent,
						start: change.start || subEvent.start,
						end: change.end || subEvent.end,
						date: change.start || subEvent.date,
					}

					return newSubEvent
				} else {
					return subEvent
				}
			}
		}
	}

	handleRealtimeSync() {
		this.enableRealtimeSync()
		this.subscribe(state => {
			this.enableRealtimeSync()
		})
	}

	enableRealtimeSync() {
		const allEvents = this.get().events

		// filter out events that are not in the future
		const today = dayjs().startOf("day")
		const events = allEvents.filter(event => {
			return event.events.some(e => {
				return dayjs(e.end).isAfter(today)
			})
		}).filter(event => {
			return event.events.length > 0
		})

		const eventIds = events.map(event => event.id)

		eventIds.forEach(eventId => {

			if (this.listeningRealtimeChangesForEvents.includes(eventId)) {
				return
			}


			const unsub = this.client.subscription("inviteChange", {
				inviteId: eventId
			}, {
				onNext: (event) => {
					if (event.type === "data") {
						console.log({next: event})
						this.update(state => {
							const index = state.events.findIndex(e => e.id === eventId)
							if (index > -1) {
								state.events[index] = event.data
							}
							return state
						})
						this.save();
					}
				},
				onError: (err) => {
					if (this.listeningRealtimeChangesForEvents.includes(eventId)) {
						this.listeningRealtimeChangesForEvents = this.listeningRealtimeChangesForEvents.filter(id => id !== eventId)
					}
					console.error(err)
				},
				onDone: () => {
					if (this.listeningRealtimeChangesForEvents.includes(eventId)) {
						this.listeningRealtimeChangesForEvents = this.listeningRealtimeChangesForEvents.filter(id => id !== eventId)
					}
					console.log("done")
				},
			})
			this.listeningRealtimeChangesForEvents.push(eventId)
			this.disableRealtimeSyncForEvent[eventId] = unsub
		})

		this.disableRealtimeSync = () => {
			Object.values(this.disableRealtimeSyncForEvent).forEach(unsub => {
				unsub()
			})
			this.disableRealtimeSyncForEvent = {}
			this.listeningRealtimeChangesForEvents = []
		}
	}
}



export const getReceivingUserId = ({
	event,
	subEvent,
	currentUserId
}: {
	event: ICalendarEvent,
	subEvent: IEvent,
	currentUserId: string
}) => {
	if (event && subEvent) {
		return subEvent.acceptedBy?.map(a => a.id)
	}

	return undefined
}


export const handleBookEvent = async (opts: {
	calendarStore: CalendarEventsStore,
	event: ICalendarEvent,
	subEvent: IEvent,
	currentUserId: string,
}) => {
	const {calendarStore, event, subEvent, currentUserId} = opts;
	await calendarStore.updateEvent(event.id, (e) => {
		const index = e.events.findIndex(
			(e2) => e2.id === subEvent.id
		);
		const currentEventState = e.events[index];
		if (!currentEventState) {
			return e;
		}
		e.events = e.events.filter(e => e.id !== subEvent.id)


		currentEventState.isBooked = true;
		const acceptedBy = currentEventState.acceptedBy || [];

		const filteredAcceptedBy = acceptedBy.filter(
			(a) => a.id !== currentUserId
		);

		const updatedAcceptedBy = [
			...filteredAcceptedBy,
			{
				id: currentUserId,
				acceptedTime: Date.now(),
			},
		]

		currentEventState.acceptedBy = updatedAcceptedBy;
		e.events = [...e.events, currentEventState];

		sendCloudEventMessage(
			IFirebaseMessagingEventsTypes.CALENDAR_EVENT_UPDATE,
			{
				eventId: event.id,
				userId: currentUserId,
				subEventId: currentEventState.id,
				timestamp: Date.now(),
				updatedEvent: currentEventState,
			},
			{
				receivingUserId:
					getReceivingUserId({
						event: e,
						subEvent: e.events[index],
						currentUserId:currentUserId,
					}) || [],
				sendingUserId: [currentUserId],
			}
		);
		return e;
	});
}


export const getEventBookUrl = (eventId: string, opts?: {
	view?: BookEventViews;
}): URL => {
	const currentUrl = new URL(window.location.origin);
	currentUrl.pathname = `/calendar/book/${eventId}`;

	if(opts?.view){
		currentUrl.searchParams.set("view", opts.view)
	}
	return currentUrl
}


export const handleWhatsappBookEventShare = (event: EventApi | string) => {
	const groupId = typeof event === "string" ? event: event.groupId;

	if(groupId){
		const url = getEventBookUrl(groupId, {
			view: BookEventViews.list
		});
		const waUrl = getWhatsappShareUrl(url.toString());
		window.open(waUrl.toString(), "_blank");
	}
}

