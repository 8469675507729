<script lang="ts">
  import "@common/i18n";
  import { currentUser, overlayModal } from "@common/basicStores";

  import BeforeInstallPromp from "@components/BeforeInstallPromp.svelte";
  import ErrorView from "@components/Error/ErrorView.svelte";
  // import FirebaseNotifications from "@components/Layout/FirebaseNotifications.svelte";

  import Layout from "@components/Layout/Layout.svelte";
  // import LoadCurrentMember from "@components/Layout/LoadCurrentMember.svelte";

  import ServiceWorker from "@components/ServiceWorker.svelte";
  import { onMount } from "svelte";
  import Routes from "./Routes/Routes.svelte";

  import { extend } from "dayjs";
  import relativeTimePlugin from "dayjs/plugin/relativeTime";
  import { removeAllDatabasesWithChunks } from "@common/utils";
  // import HandleLanguages from "@components/Utils/HandleLanguages.svelte";
  import GlobalAudio from "@components/Utils/GlobalAudio.svelte";
  import BottomBarNotifications from "@components/Notifications/BottomBarNotifications.svelte";
  // import TippySvelte from "@components/TippySvelte/TippySvelte.svelte";
  // import LoadNetworkManager from "@components/Utils/LoadNetworkManager.svelte";
  //   import { getBrowserFingerprint } from "@common/browserFingerprint";
  // import BrowserFingerprint from "@components/Utils/BrowserFingerprint.svelte";
  import KnockKnockSound from "@components/Utils/KnockKnockSound.svelte";
    import WakeLock from "@components/Utils/WakeLock.svelte";

  extend(relativeTimePlugin);

  onMount(() => {
    removeAllDatabasesWithChunks();
  });
</script>

<Layout isModalOpen={$overlayModal.isOpen}>
  <ServiceWorker />
  <BeforeInstallPromp />
  <!-- <FirebaseNotifications /> -->
  <!-- <HandleLanguages /> -->
  <GlobalAudio />
  <BottomBarNotifications />
  <!-- <LoadNetworkManager /> -->
  <!-- <BrowserFingerprint /> -->
  <KnockKnockSound />
  <WakeLock />
  <!-- <LocalRoom /> -->

  <!-- <LoadCurrentMember> -->
    <Routes />
  <!-- </LoadCurrentMember> -->

  <svelte:fragment slot="modal">
    {#if $overlayModal.component}
      <svelte:component
        this={$overlayModal.component}
        {...$overlayModal.props || {}}
      />
    {:else}
      <ErrorView error={new Error("No Modal Provided")} />
    {/if}
  </svelte:fragment>
</Layout>
