<script lang="ts">
	import type { ICustomButton } from "@common/button";
	import NoMic from "@icons/NoMic.svelte";
	import NoScreen from "@icons/NoScreen.svelte";
	import NoSound from "@icons/NoSound.svelte";
	import NoVideo from "@icons/NoVideo.svelte";
	import RoomButtonsContainer from "../Views/RoomButtonsContainer.svelte";

	$: customButtons = [
		{
			id: "videoShare",
			icon: NoVideo,
		},
		{
			id: "screenShare",
			icon: NoScreen,
		},
		{
			id: "muteAudioInput",
			icon: NoMic,
		},

		{
			id: "muteAudioOutput",
			icon: NoSound,
		},
	] as ICustomButton[];
</script>

<RoomButtonsContainer>
	{#each customButtons as button (button.id)}
		<button
			class:buttonActive={button.isActive}
			class="customIconButton {button.class || ''}"
			on:click={button.onClick}
		>
			<span class="inline-block h-5 w-5">
				<svelte:component this={button.icon} />
			</span>
		</button>
	{/each}
</RoomButtonsContainer>

<style lang="postcss">
	.customIconButton {
		@apply btn btn-circle btn-ghost mr-2 border-opacity-50 border border-base-content;
	}

	.view-video-stream {
		@apply btn-success;
	}

	.buttonActive {
		@apply border border-base-content border-opacity-50 bg-neutral "hover:border-error";
	}
</style>
