import {extend} from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import IsoWeek from 'dayjs/plugin/isoWeek';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'


extend(AdvancedFormat);
extend(LocalizedFormat);
extend(IsoWeeksInYear);
extend(IsoWeek);