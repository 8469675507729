<script lang="ts">
	export let value: string;
	export let placeholder: string;
  export let languageDirection: "rtl" | "ltr" = "ltr"; 
  let className: string = ""

  export {
    className as class
  }
</script>

<input
  on:keydown
  placeholder={placeholder}
  bind:value
  type="text"
  style:direction={languageDirection}
  class={className}
/>

<style lang="postcss">
  input{
    @apply input w-full h-full rounded-xl bg-neutral;
  }
</style>