import { createInstance } from "localforage";
import { tick } from "svelte";
import { notificationController } from "./basicStores";
import type { CalendarEventsStore } from "./calendar";
import { getLocalCalendarEvents } from "./calendarUtils";
import { connectionManagerStorageKey } from "./constants";
import { getCalendarEventsStore } from "./getCalendarStore";
// import { customVideoListManager, notificationController } from "./basicStores";
// import { CustomVideoList } from "./customVideoList";
import { ReactiveObject } from "./ReactiveObject";
import type { RemoteController} from "./RemoteController";
import type { RoomStore } from "./roomStore";


export interface IConnectionManager{
	connected: boolean;
	roomStore?: RoomStore;
	roomName?: string;
	userId?: string;
	ready?: boolean;
	importedInitialData?: boolean
	syncTime?: number;
	synced?: boolean;
	syncing?: boolean;
	remoteController?: RemoteController
}


export class ConnectionManager extends ReactiveObject<IConnectionManager>{
	public loaded: boolean | Promise<boolean>;
	public loading: boolean = false
	public loadedResolver: ((val:boolean) => void) | undefined;
	public storage = createInstance({
		name: connectionManagerStorageKey
	})

	public events = new EventTarget();
	public persistentKeys: (keyof IConnectionManager)[] = [
		"userId",
		"roomName",
		"connected",
		"importedInitialData",
		"syncTime"
	]
	constructor(options: Partial<IConnectionManager> = {} as any) {
		const defaultOptions:IConnectionManager = {
			connected: false,
			roomStore: undefined,
			roomName: "",
			userId: "",
		};
		super({...defaultOptions, ...options});
		this.loaded = new Promise((res) => {
			this.loadedResolver = res;
		})
	}

	async save(){
		const state = this.get();
		for(let key of this.persistentKeys){
			await this.storage.setItem(key, state[key]);
		}
	}

	async load(){
		if(this.loading) return
		if(typeof this.loaded === "boolean" && this.loaded) return
		console.log("Loading connection manager");
		this.loading = true;
		let data:any = {}
		for(let key of this.persistentKeys){
			const item = await this.storage.getItem(key);
			if(item !== null){
				data[key] = item;
			}
		}
		this.update(state => {
			return {
				...state,
				...data
			}
		})

		this.setItem("ready", true);
		this.loadedResolver?.(true);
		this.loaded = true;
		this.loading = false
		this.loadedResolver = undefined;
	}

	async reconnect(RoomStoreClass: typeof RoomStore){
		const state = this.get();
		state?.roomStore?.destroy();

		if(state.roomName){
			const roomStore = new RoomStoreClass(state.roomName);
			await roomStore.init();
			this.setItem("roomStore", roomStore);
			this.setItem("connected", true);
		}else{
			throw new Error("No room name");
		}
	}

	disconnect(){
		this.get()?.roomStore?.destroy();
		this.setItem("connected", false);
		this.setItem("roomStore", undefined);
	}

	connect(roomName: string, RoomStoreClass: typeof RoomStore){
		this.setItem("roomName", roomName);
		this.reconnect(RoomStoreClass);
	}

	setRemoteController(roomStore: RoomStore, remoteController:RemoteController){
		this.update(state => {
			state.remoteController = remoteController;
			state.roomStore = roomStore;
			return state
		})
	}


	async syncList(filterList?: (listId: string) => boolean){

		try{
			if(this.getItem("syncing")){
				return;
			}
			this.setItem("syncing", true);
			const {remoteController} = this.get(); 
			const calendarEventsStore = await getCalendarEventsStore()
			notificationController.add({
				id: "syncingCalendar",
				message: "Syncing Calendar Data... Could take a while",
				duration: 60 * 1000 * 10
			})

			const events = await getLocalCalendarEvents();
	
			const data = await remoteController?.requestCalendarData({
				onProgress: (stats) => {
					console.log({stats})
					notificationController.updateNotification("syncingCalendar", notification => {
						notification.message = `Syncing Data... Events: (${
							stats.currentEventCount
						}/${
							stats.eventCount
						}) done - Changes: (${
							stats.currentChangeCount
						}/${
							stats.changeCount
						})`;
						return notification;
					})
				},
				getCustomListOptions: {
				},
				filterList,
			})
			notificationController.clear();
			await tick()
	
			if(!data){
				notificationController.add({
					message: "Could not sync data"
				})
				return;
			}

			calendarEventsStore.mergeChanges(data.changes);
			calendarEventsStore.mergeEvents(data.events);
			notificationController.add({
				message: "Syncing Completed",
			})
	
			this.setItem("synced", true);
		}catch(err){
			console.error(err);
			this.setItem("syncing", false);
		}
	}


	async enableDataRealtimeSync(calendarEventsStore?: CalendarEventsStore){
		if(!calendarEventsStore){
			calendarEventsStore = await getCalendarEventsStore();
		}
		const addListeners = () => {
			const removeListeners = () => {
				const removeEventCreateListener = calendarEventsStore?.addListener("eventCreate", ({
					detail: {
						event
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.call("setEvent", {
						event
					})
				})

				const removeEventUpdateListener = calendarEventsStore?.addListener("eventUpdate", ({
					detail: {
						event
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.call("setEvent", {
						event
					})
				})

				const removeChangeCreateListener = calendarEventsStore?.addListener("eventChangeCreate", ({
					detail: {
						eventChange
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.callEach("setChange", {
						change: eventChange
					})
				})
				const removeChangeUpdateListener = calendarEventsStore?.addListener("eventChangeUpdate", ({
					detail: {
						eventChange
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.callEach("setChange", {
						change: eventChange
					})
				})

				const removeChangesUpdateListener = calendarEventsStore?.addListener("changesUpdated", ({
					detail: {
						changes
					}
				}) => {
					const rpc = this.get()?.remoteController?.rpc
					if(!rpc) return;
					rpc.callEach("setChanges", {
						changes
					})
				})

				return () => {
					removeEventCreateListener?.();
					removeEventUpdateListener?.();
					removeChangeCreateListener?.();
					removeChangeUpdateListener?.();
					removeChangesUpdateListener?.();
				}
			}

			return () => {
				removeListeners()
			}
		}

		let removeListeners = addListeners();
		return () => {
			removeListeners();
		}
	}
}


export interface IMulipleConnectionManager{
	connections: ConnectionManager[];
}
// export class MultipleConnectionManager extends ReactiveObject<IMulipleConnectionManager>{
// 	public storage = createInstance({
// 		name: "MultipleConnectionManager",
// 	})

// 	constructor(){

// 	}
// }