<script lang="ts">
  import SyncLoader from "@components/Loaders/SyncLoader.svelte";
  import type { SvelteComponentTyped } from "svelte";
  //@ts-ignore
  import Loadable from "svelte-loadable/Loadable.svelte";

  export let loader: () => Promise<any> | any;
  export let loaderComponent:
    | typeof SvelteComponentTyped<any, any, any>
    | undefined = undefined;

  $: _loader = loader as any;

  const logError = (err: any) => {
    console.error(err);
    return `${err}`;
  };
</script>

<Loadable let:component loader={_loader}>
  <div slot="loading" class="h-full w-full overflow-hidden">
    {#if loaderComponent}
      <svelte:component this={loaderComponent} />
    {:else}
      <div
        class="flex items-center justify-center w-full h-full overflow-hidden bg-base-300"
      >
        <SyncLoader color="white" size={20} />
      </div>
    {/if}
  </div>
  <div
    slot="error"
    let:error
    class="justify-center w-full h-full overflow-hidden flex-center bg-base-300 text-base-content"
  >
    {logError(error)}

    <br />
  </div>
  <!-- {#if loaderComponent}
    <svelte:component this={loaderComponent} />
  {:else} -->
    <slot {component} />
  <!-- {/if} -->
</Loadable>
