<script lang="ts">
  import { lastJoinedRoom } from "@common/uiStores";
  import { generateUniqueRoomId } from "@common/roomUtils";
  import DoorAnimation from "@components/Door/DoorAnimation.svelte";
  import { navigate } from "svelte-navigator";

  let value: string = "";
  let rejoinRoomInterval: any;
  let rejoinRoomRemainingTime: number = 30;
  let doorIsOpen: boolean = false;
  let animationDuration: number = 0.7;

  let audioBlob: Blob | undefined = undefined;

  const playDoorOpeningAnimation = (timeout: number = 1000) => {
    return new Promise<void>((res, rej) => {
      doorIsOpen = true;
      setTimeout(() => {
        doorIsOpen = false;
        res();
      }, timeout);
    });
  };

  const goToNewRoom = async () => {
    await playDoorOpeningAnimation(animationDuration * 1000);
    const roomId = generateUniqueRoomId();
    navigate(`/${roomId}`);
  };

  const rejoinRoom = async () => {
    await playDoorOpeningAnimation(
      animationDuration * 1000
    );
    navigate(`/${rejoinRoomId}`);
    $lastJoinedRoom = "";
  };

  $: rejoinRoomId = $lastJoinedRoom;

  $: {
    if (rejoinRoomId) {
      clearInterval(rejoinRoomInterval);
      rejoinRoomInterval = setInterval(() => {
        rejoinRoomRemainingTime--;
        if (rejoinRoomRemainingTime === 0) {
          clearInterval(rejoinRoomInterval);
        }
      }, 1000);
    } else {
      clearInterval(rejoinRoomInterval);
    }
  }
  const handleAutoPlay = (element: HTMLMediaElement) => {
    element.play().catch((err) => {
      console.log(err);
    });
  };
</script>

<div class="h-full w-full flex flex-col items-center bg-base-300 pt-16">
  <div class="flex flex-col items-center justify-center w-full max-w-xl">
    <div class="mb-2">
      {#key rejoinRoomId}
        <DoorAnimation
          bind:isOpen={doorIsOpen}
          animateOnRender={rejoinRoomId ? true : false}
          duration={
            animationDuration
          }
        />
        <audio paused use:handleAutoPlay />
      {/key}
    </div>
    <!-- <span
      role="button"
      on:click={goToNewRoom}
      on:keydown
      class="inline-block h-24 w-24 mb-6"
    >
      <Babelbab />
    </span> -->

    <div class="flex flex-row items-center justify-center my-10">
      <button
        class:mr-5={rejoinRoomId && rejoinRoomRemainingTime > 0}
        on:click={goToNewRoom}
        class="btn btn-lg bg-base-content text-base-300 hover:text-base-300 hover:bg-base-content hover:bg-opacity-80"
        >Enter</button
      >

      {#if rejoinRoomId && rejoinRoomRemainingTime > 0}
        <button on:click={rejoinRoom} class="btn btn-lg"
          >Rejoin ({rejoinRoomRemainingTime})</button
        >
      {/if}
    </div>

    <div class="grid gap-2 text-center">
      <h1
        class="text-4xl mb-4 font-bold gap-1.5 flex flex-row items-center justify-center"
      >
        <span class="text-base-content/80"> bab </span>
        <span class="text-base-content/50"> el </span>
        <span class="text-base-content/80"> bab </span>
      </h1>
      <p class="text-base-content text-opacity-80">The most private chat</p>

      <div class="flex flex-col md:flex-row items-center justify-center">
        {#each ["No email address.", "No phone number.", "No app download."] as item}
          <p class="text-base-content text-opacity-80 md:mr-1.5 md:mb-0 mb-2">
            {item}
          </p>
        {/each}
      </div>
    </div>
  </div>
</div>
