import { createInstance } from "localforage";
import { uid } from "uid";
import { ConnectionManager, type IConnectionManager } from "./ConnectionManager";
import { networksStorageKey } from "./constants";
// import type { CustomVideoList } from "./customVideoList";
import { ReactiveObject } from "./ReactiveObject";


export interface INetworkDevice {

}

export interface INetwork {
	id: string;
	name: string;
	roomName: string;
	temporary?: boolean;
	_connectionManager: ConnectionManager
	// _syncLists?: CustomVideoList[]
}


export interface INetworks {
	items: INetwork[];
	ready: boolean;
}


export class Networks extends ReactiveObject<INetworks>{

	storage = createInstance({
		name: networksStorageKey 
	})

	public loading: boolean | Promise<boolean> = false;
	public loadingResolver: (val: boolean) => void = () => { };

	constructor(options: Partial<INetworks> = {} as any) {
		const defaultOptions: INetworks = {
			items: [],
			ready: false
		};
		super({ ...defaultOptions, ...options });
	}

	async addNetwork(network: Partial<INetwork>): Promise<INetwork> {
		const networkId = network.id || uid();
		const roomId = network.roomName || uid();
		const connectionManager = network._connectionManager || this.getConnectionManager(networkId, roomId);
		const _network: INetwork = {
			id: uid(),
			name: "New Network",
			roomName: uid(),
			temporary: true,
			_connectionManager: connectionManager,
			...network
		};
		this.setItem("items", [...this.get().items, _network]);
		await this.save();
		return _network;
	}
	async removeNetwork(id: string) {
		this.setItem("items", this.get().items.filter(item => item.id !== id));
		await this.save()
	}

	getConnectionManager(networkId: string, roomName: string) {
		const manager = new ConnectionManager({
			roomName,
			connected: true
		});
		manager.loaded = true;
		manager.setItem("ready", true);
		manager.setItem("connected", true);

		manager.storage = createInstance({
			name: `connection-manager-storage-${networkId}`
		})
		return manager;
	}


	async save() {
		this.storage.setItem(
			"items",
			this.get()
				.items
				.filter(item => !item.temporary)
				.map(item => {
					const { _connectionManager, ...rest } = item;
					return rest;
				})
		)
	}

	async load() {
		const ready = this.get().ready
		if (ready) return

		if (typeof this.loading === "boolean" && this.loading) {
			return
		}

		if (typeof this.loading === "object") {
			await this.loading;
			if (this.get().ready) return
			return
		}

		this.loading = new Promise((res) => {
			this.loadingResolver = res;
		});
		const items: INetwork[] | null = await this.storage.getItem("items");
		if (items) {
			const _items = items.map(async item => {
				const connectionManager = new ConnectionManager({
					roomName: item.roomName,
					connected: true
				});
				connectionManager.storage = createInstance({
					name: `connection-manager-storage-${item.id}`
				})

				item._connectionManager = connectionManager;
				return item;
			})

			const resolvedItems = await Promise.all(_items);

			this.updateItem("items", _items => [
				...resolvedItems
					.filter(
						item => _items
							.find(_item => _item.id === item.id) === undefined
					),
				..._items
			]);
		}
		this.setItem("ready", true);
		this.loadingResolver(false);
		this.loadingResolver = () => { };
		this.loading = false;
	}
}