<script lang="ts">
  import { onMount, tick } from "svelte";
  
  export let animateOnRender: boolean = true;
  export let isOpen = true;
  export let size: number = 200;
  export let duration: number = 1;

  if(animateOnRender){
    isOpen = !isOpen
  }
  
  onMount(async () => {
    if (animateOnRender) {
      await tick();
      isOpen = !isOpen;
    }
  });

  export function toggleDoor() {
    isOpen = !isOpen;
  }
  export function openDoor() {
	isOpen = true;
  }

  export function closeDoor() {
	isOpen = false;
  }
</script>

<div class="circle" style="--size: {size}px;">
  <div class="frame">
    <div class="door {isOpen ? 'open-door' : ''}"  style="--duration:{duration}s"/>
  </div>
</div>

<style>
  .circle {
    width: var(--size, 200px);
    height: var(--size, 200px);
    background-color: var(--circleBackgroundColor, #555);
    border-radius: 15%;
    position: relative;
    overflow: hidden;
    perspective: 800px; /* Reduced perspective for a more pronounced 3D effect */
    cursor: pointer;
  }

  .frame {
    width: 44%;
    height: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
  }

  .door {
    width: 100%;
    height: 100%;
    background-color: var(--doorBackgroundColor, #999);
    position: absolute;
    right: 0;
    transform-origin: left center;
    transition: transform var(--duration, 0.5s);
  }

  .open-door {
    transform: rotateY(-45deg) skewY(20deg);
    z-index: 50;
  }
</style>
