<script lang="ts">
  import ErrorView from "@components/Error/ErrorView.svelte";
  import type { SvelteComponentTyped } from "svelte";
  import Ban from "../icons/Ban.svelte";

  export let condition: ((url: URL) => Promise<boolean> | boolean) | undefined =
    undefined;
  export let BlockedViewComponent:
    | typeof SvelteComponentTyped<any, any, any>
    | undefined = undefined;
</script>

{#if condition}
  {#await condition(new URL(window.location.href))}
    waiting...
  {:then allowRoute}
    {#if !allowRoute}
      {#if BlockedViewComponent}
        <svelte:component this={BlockedViewComponent} />
      {:else}
        <div class="flex flex-col items-center justify-center h-full gap-4">
          <span class="w-10 h-10">
            <Ban />
          </span>
          <span class="text-lg"> Not Allowed </span>
        </div>
      {/if}
    {:else}
      <slot />
    {/if}
  {:catch err}
    <ErrorView error={err} />
  {/await}
{:else}
  <slot />
{/if}
