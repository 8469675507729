
import { ExtendedWritable, localStoragePresistanceStore } from "./ExtendedWritable";
import type { BeforeInstallPromptEvent } from '@ServiceWorker/commonWorkerUtils';
import { UiThemes } from "./styles";
import { OverlayModalController } from './OverlayModalContainer'
import type { RoomStore, IMember } from './roomStore';
import { NotificationController } from "./notifications";
import { ConnectionManager } from "./ConnectionManager";
import { Networks } from "./networks";
import type { CalendarEventsStore } from "./calendar";
import { Tasks } from "./tasks";
import { LocalRoom } from "./localRoom";
import { ListManager } from "./lists";
import { generateRandomId } from "./roomUtils";
import { WakeLockApi } from "./wakeLockApi";

const randomUserId = generateRandomId();

export const deferredInstallPrompt = new ExtendedWritable<BeforeInstallPromptEvent>();
export const swRegistration = new ExtendedWritable<ServiceWorkerRegistration | undefined>();
export const rootElement = new ExtendedWritable<HTMLElement | undefined>();
export const theme = new ExtendedWritable<UiThemes>(UiThemes.dark);
export const selectedLang = new ExtendedWritable<string>("ar");
export const defaultLang = new ExtendedWritable<string>("en");
export const targetLang = new ExtendedWritable<string>("");
export const secretCodeMode = new ExtendedWritable<boolean>(false);

export const overlayModal = new OverlayModalController({
	isOpen: false,
	component: null,
	props: undefined,
})

export const workerRpc = new ExtendedWritable<any>(
	// WorkerRpc.createUsingBroadcastChannel(SW_BROADCAST_CHANNEL_KEY)
);

export const currentUser = new ExtendedWritable<Partial<IMember> | undefined>({
	id: randomUserId,
	displayName: randomUserId,
})

export const selectedDevices = new ExtendedWritable<{
	audioInput?: MediaDeviceInfo,
	videoInput?: MediaDeviceInfo,
	audioOutput?: MediaDeviceInfo,
}>({})


export const allRooms = new ExtendedWritable<{
	[key: string]: RoomStore | undefined
}>({})





export const theatreComponentProps = new ExtendedWritable<{
	embedUrl: string,
} | undefined>(undefined)

export const mainIframe = new ExtendedWritable<{
	src: string;
	title: string;
} | undefined>(undefined)

export const joinConfirmation = localStoragePresistanceStore(
	new ExtendedWritable<{
		videoInput: boolean;
		audioInput: boolean;
		confirmation: boolean;
	}>({
		videoInput: false,
		audioInput: false,
		confirmation: false,
	}),
	["confirmation"],
	"joinConfirmation"
);


export const globalMedia = new ExtendedWritable<{
	audioContext?: AudioContext;
	audioStream?: MediaStream;
	videoStream?: MediaStream;
	screenshareStream?: MediaStream;
	audioOutput: HTMLAudioElement[]
}>({
	audioContext: undefined,
	audioStream: undefined,
	videoStream: undefined,
	screenshareStream: undefined,
	audioOutputStream: undefined,
	audioOutput: []
})


export const tasks = new Tasks()



export interface ISettings {
	translationEnabled: boolean;
}

export const settings = localStoragePresistanceStore(
	new ExtendedWritable<ISettings>({
		translationEnabled: true
	}),
	[],
	"settings"
);


export const notificationController = new NotificationController();
export const connectionManager = new ConnectionManager();
export const networks = new Networks();

export const calendarEventsStore = new ExtendedWritable<CalendarEventsStore | undefined>()
export const localRoom = new LocalRoom({})
export const browserFingerprint = new ExtendedWritable<string | undefined>()

export const listManager = new ListManager()


export const toolsMenuSettings = new ExtendedWritable<{
	collapsed: boolean;
}>({
	collapsed: false
})
export const currentRoom = new ExtendedWritable<RoomStore | undefined>(undefined)

export const videoStreamViewActive = new ExtendedWritable<boolean>(false)

export const playKnockKnockSound = new ExtendedWritable<boolean>(false)
export const isMessageListAtBottom = new ExtendedWritable<boolean>(true)

export const wakeLockApi = new WakeLockApi()


export const driverAppDemo = new ExtendedWritable<any>()
export const driverPwaInstall = new ExtendedWritable<any>();
export const pwaInstallActive = new ExtendedWritable<boolean>(false);