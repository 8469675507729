<script lang="ts">
	import { ExtendedWritable } from "@common/ExtendedWritable";
	import SyncLoader from "@components/Loaders/SyncLoader.svelte";
	import OverlayModalContainer from "./OverlayModalContainer.svelte";

	export let message: ExtendedWritable<string> = new ExtendedWritable("");
</script>

<OverlayModalContainer>
	<div slot="header" />
	<div
		class="flex flex-col items-center justify-center w-full h-full overflow-hidden bg-base-300"
	>
		{#if $message}
			<div class="mb-2">
				{$message}
			</div>
		{/if}
		<SyncLoader color="white" size={20} />
	</div>
</OverlayModalContainer>
