import { calendarEventsStore } from "./basicStores"
import { CalendarEventsStore } from "./calendar"
import { getApiClient } from "./trpc"

export const getCalendarEventsStore = (init: boolean = true): CalendarEventsStore=> {
	if(!calendarEventsStore.get()){
		calendarEventsStore.set(new CalendarEventsStore({
			events: [],
			changes: []
		}, getApiClient()))
		if(init){
			calendarEventsStore.get()?.init()
		}
	}
	return calendarEventsStore.get()!
}