import { createTRPCClient, type TRPCClient } from '@trpc/client';
import { httpLink } from '@trpc/client/links/httpLink';
import { splitLink } from '@trpc/client/links/splitLink';
import { createWSClient, wsLink } from '@trpc/client/links/wsLink';
import type { AppRouter } from '@app/api/src/index'
import { ExtendedWritable } from './ExtendedWritable';
import type { ICalendarRangeInvite } from '@app/api/src/common/calendarEvents';


export type ApiClient = TRPCClient<AppRouter>
export const clientStore = new ExtendedWritable<ApiClient | null>(null)

export const API_URLS: {
	ws: string;
	http: string;
} = {
	ws: import.meta.env.DEV ? `ws://localhost:3212` : `wss://trpc.babelbab.com`,
	http: import.meta.env.DEV ? `http://localhost:3212` : `https://trpc.babelbab.com`,
}


export interface ICreateClientOptions {
	ws?: string;
	http?: string
}


export const getApiClient = (): ApiClient => {
	if(clientStore.get() === null){
		clientStore.set(createClient())
	}
	return clientStore.get()!
}


export const createClient = ({
	ws = API_URLS.ws,
	http = API_URLS.http
}: ICreateClientOptions = {}): ApiClient => {
	const wsClient = createWSClient({
		url: ws,
	});
	const client = createTRPCClient<AppRouter>({
		links: [
			splitLink({
				condition(op) {
					return op.type === 'subscription';
				},
				true: wsLink({
					client: wsClient,
				}),
				false: httpLink({
					url: http,
				}),
			}),
		],
	});

	return client;
}



export const getInviteStore = async ({
	client,
	inviteId
}:{
	client: ApiClient, 
	inviteId:string
}) => {
	const writable = new ExtendedWritable<ICalendarRangeInvite | null>(null);

	const currentState = await client.query("getInvite", {
		inviteId
	})

	writable.set(currentState)

	client.subscription("inviteChange", {
		inviteId
	}, {
		onNext: (event) => {
			if(event.type === "data"){
				writable.set(event.data)
			}
		},
		onError: (err) => {
			console.error(err)
		},
		onDone: () => {
			console.log("done")
		}
	})

	return writable

}

